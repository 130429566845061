import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';

document.addEventListener("DOMContentLoaded", () => {

  // Open nocowanie.pl modal
  const modal = document.querySelector(".js-modal");
  const modalActivators = document.querySelectorAll(".js-modal-open");
  const modalClosers = document.querySelectorAll(".js-modal-close");

  modalActivators.forEach((activator) => {
    activator.addEventListener("click", (e) => {
      e.preventDefault();
      modal.classList.add("js-active");
    });
  });
  modalClosers.forEach((closer) => {
    closer.addEventListener("click", (e) => {
      e.preventDefault();
      modal.classList.remove("js-active");
    });
  });

  // GLightbox
  if (document.querySelector('.js-gallery')) {
    const lightbox = GLightbox({
      selector: '.js-gallery a',
      touchNavigation: true,
      loop: true,
      autoplayVideos: true,
    });
  }

  // Mobile menu
  const menu = document.querySelector(".js-mobile-menu");
  const menuToggler = document.querySelector(".js-mobile-menu-open");

  menuToggler.addEventListener("click", (e) => {
    e.preventDefault();
    menu.classList.toggle("js-active");
    menuToggler.classList.toggle("js-active");
  });
});